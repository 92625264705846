import IntegrationModal from "./integration";
import {Auth0ProviderWithNavigate} from '../../authProvider'

const Modals = () => {
  return (
    <>
    <Auth0ProviderWithNavigate>
      <IntegrationModal />
    </Auth0ProviderWithNavigate>
    </>
  );
};

export default Modals;
